
<template>
    <div>
        <b-overlay :show="is_loading">
        <b-card class="w-100 chart">
            <CardHeader>
                <template #title>
                    Organizational Structure View
                </template>
        
                <template #buttons>
                    <h6 id="zoomLevel" class="m-1" > Zoom Level </h6>
                    <b-button  @click='increaseView()' variant="primary" size="sm" class="me-2">
                        <font-awesome-icon icon="fa-plus" class="fa-lg"/>
                    </b-button>
                    <b-button @click='decreaseView()' variant="primary" size="sm" class="me-2"> 
                        <font-awesome-icon icon="fa-minus" class="fa-lg"/>
                    </b-button>
                    <b-button @click='expandAll()' size="sm" class="me-2">
                    <font-awesome-icon class="me-2" icon="fa-plus"/>
                    Expand All
                    </b-button>
                    <b-button @click='resetChart()' size="sm">
                    <font-awesome-icon class="me-2" icon="fa-undo"/>
                    Reset Chart
                    </b-button>
                    
                </template>
            </CardHeader> 
                <div v-if="show_chart" id="chart-container">
                </div>
        </b-card>
        </b-overlay>
    </div>
</template>
<script>
import { levelComputed, levelMethods ,peopleComputed, peopleMethods, profileComputed, profileMethods } from '@/state/helpers'
import 'vue-organization-chart/dist/orgchart.css'
import OrgChart from '@/plugins/orgchart/orgchart.js'
import CardHeader from '@/components/widgets/cardHeader.vue';
import _ from 'lodash'

export default {
  components: { CardHeader },
  computed: {
      ...levelComputed,
      ...peopleComputed,
      ...profileComputed
  },
  watch: {
      current_level_id: {
          handler() {
             this.setSelectedOrgLevel(this.current_level_id);
             if(this.current_level_id !== this.selectedOrgLevel) {
                this.mainLevel = this.current_level_id;
                this.is_loading = true;
                this.loadEmployeesData(this.selected_level.id)
                .then(()=>{
                    this.$nextTick(()=>{
                        let promises = [
                         this.executeOrgChart(),
                         this.clearDefaults()
                        ]
                        Promise.all(promises)
                        .then(()=>{ this.$nextTick(()=>{ this.updateNodeSizes(); this.setDefaults() , this.resizeOrg() }); })
                        this.is_loading = false
                    })
                })
             }
          },
      },
      hiddenOrgChart() {
            this.is_loading = true;
                this.$nextTick(()=>{
                        let promises = [
                         this.executeOrgChart(),
                         this.clearDefaults()
                        ]
                        Promise.all(promises)
                        .then(()=>{ this.$nextTick(()=>{ this.updateNodeSizes(); this.setDefaults(), this.resizeOrg() }); })
                        this.is_loading = false
                })
      },
      levels: {
        handler() {
            this.is_loading = true;
            this.loadEmployeesData(this.selected_level.id)
            .then(()=>{
                this.$nextTick(()=>{
                        let promises = [
                         this.executeOrgChart(),
                         this.clearDefaults()
                        ]
                        Promise.all(promises)
                        .then(()=>{ this.$nextTick(()=>{ this.updateNodeSizes(); this.setDefaults() , this.resizeOrg() }); })
                        this.is_loading = false
                })
            })
          },
      },
      scale: {
        handler() {
            const org = document.querySelector(".orgchart");
            let number = this.scale
            org.style.transform = 'matrix(0.815376, 0, 0, 0.815376, -76, -5) scale(' + `${number}` + ')';
        },
    },
  },
  methods: {
    ...levelMethods,
    ...peopleMethods,
    ...profileMethods,
    executeOrgChart() {
      return new Promise((resolve) => {
        let hasChilderen = window.webix.$$('levelsTree').getFirstChildId(this.current_level_id);
        let treeHead = new Array(window.webix.$$('levelsTree').getItem(this.current_level_id));
        if (hasChilderen) {
            let serialized_data = window.webix.$$('levelsTree').serialize(this.current_level_id, true);
            treeHead.forEach(function (element) {
            element.data =  serialized_data;
            });
        }
        let serialized_data = this.flattenArray(treeHead);
        this.level_data = this.processLevels(serialized_data);
        this.FinalJSONvalue = this.level_data[0];
        this.initChart()
            .then(()=>{ resolve() })
        })
    },
     initChart(){
        return new Promise((resolve) => {
          if(this.orgchart_instance != null){
              this.show_chart = false;
              this.orgchart_instance = null;
          }
          this.$nextTick(()=>{
              this.show_chart = true;
              setTimeout(()=>{
                  this.orgchart_instance = new OrgChart({
                      'chartContainer': '#chart-container',
                      'data' : this.FinalJSONvalue,
                      'pan' : true,
                      'depth': 2,
                      'nodeID': 'id',
                      'createNode': function(node, data) {  
                        if (data.mainID == data.id) {
                            node.classList.add("focused");
                        }
                        let menuIcon = document.createElement('div');
                        if (data.lead_user.length == 0 || data.lead_user.active == 0){
                            // No user
                            menuIcon.innerHTML = `<div class="styling-container"><div class="main-content-container" style="display: inline-flex">
                                                  <img class="smaller-image-height avatar-md org-profile-img rounded-circle img-thumbnail header-profile-user-view img-fluid" src="/img/user.png"></img>
                                                  <div class="heading"><span id="jobTitle">No Lead User </span><br>People (${data.count})</div></div> </div>`;
                        } else {
                            //let link = _.find(data.people, {id:Number(data.lead_user.id)});  
                            if(data.people.current_job.length == 0) {
                                menuIcon.innerHTML = `<div class="styling-container"><div class="main-content-container" style="display: inline-flex; text-align: center">
                                                      <img class="smaller-image-height avatar-md org-profile-img rounded-circle img-thumbnail header-profile-user-view img-fluid" src="${data.people.avatar_src}">
                                                      <div class="heading"><span>${data.lead_user.name} ${data.lead_user.last_name}</span><br>
                                                      </div></div><div class=second-container></div><span id="jobTitle">No Position People (${data.count})</span> </div>`;
                            } else {
                                menuIcon.innerHTML = `<div class="styling-container"><div class="main-content-container" style="display: inline-flex; text-align: center;">
                                                      <img class="smaller-image-height avatar-md org-profile-img rounded-circle header-profile-user-view img-fluid" onerror="this.src=/img/user.png';" src="${data.people.avatar_src}">
                                                      <div class="heading"><span>${data.lead_user.name} ${data.lead_user.last_name}</span><br>
                                                      </div></div><div class=second-container><span id="jobTitle">${data.people.current_job.title}</span><br> People (${data.count}) </div></div>`;
                            }
                        }
                        node.appendChild(menuIcon);
                        let clickedNode = node.querySelector('.main-content-container');
                        clickedNode.addEventListener('click', () => {

                        window.orgChartContext.setLevel(data.id)
                        window.orgChartContext.selectedOrgLevel = data.id
                        })
                      } 
                  });
                  resolve();
              }, 250);
          })
        })
      },
      setLevel(data) {
        this.setSelectedOrgLevel(data);
      },
      processLevels(levels) {
        //   this.show = false;
        let people = this.levelEmployeesData
        //console.log(JSON.parse(JSON.stringify(value)));
          var data = levels;
          if (data == null) {
              data = [];
          }
          else {
              data.forEach((element) => {
                  // give element the required fields
                  element["parent"] = element.$parent; //used for webix related stuff
                  element["value"] = element.name; //used for webix related stuff
                  element["data"] = []; // array of children for the node
                  element['people'] = _.find(this.levelEmployeesData, {id:Number(element.lead_user.id)}); 
                  let count = 0
                    people.forEach(person => {
                        if(person.archived == null) {
                            let value = person.levels
                            value.forEach(level => {
                            if(Number(level.id) == Number(element.id)) {
                                count = count + 1 
                            }
                        })
                        }
                    })
                  element['count'] = count
                  if (element['mainID']) {
                    element['mainID'] = null;
                  }

                  if (element.id == this.current_level_id) {
                    element['mainID'] = this.current_level_id;
                  }
                  // remove null values
                  Object.keys(element).forEach((k) => element[k] == null && delete element[k]);
              });
              data.forEach((ele) => {
                
                  let parent_id = ele.$parent;
                  if (parent_id == null) {
                      //* Parent item -- do nothing
                  }
                  else {
                      //* If element is a child element, push to children array.
                      data.forEach((d) => {
                          if (d.id === parent_id) {
                              let childArray = d.data;
                              if (!childArray) {
                                  childArray = [];
                              }
                              childArray.push(ele);
                              d.children = childArray;
                          }
                      });
                  }
              });
          }
          //this.show = true;
          let JSONvalue = JSON.parse(JSON.stringify(data));
          return JSONvalue;
      },
      flattenArray(treeHead){
          //get all the items fron the current level and lower
          const flatten = (members) => {
              let data = [];
              return members.map(m => {
                  if (m.data && m.data.length) {
                      data = [...data, ...m.data];
                  }
                  return m;
              }).concat(data.length ? flatten(data) : data);
          };
          let flat = flatten(treeHead);
          flat.forEach( item => {
              if(item.data){
                  delete item.data;
              }
          });
          return this.flat_array = flat;
      },
      resetChart() {
        // this.nodes = this.defaultNodes
        const org = document.querySelector(".orgchart");
        this.scale = 1
        Array.from(document.getElementsByClassName('node')).forEach(function (element) {
            if(!element.classList.contains('slide-up') && !element.classList.contains('default')){
                element.classList.add('slide-up');
            }
        });
        Array.from(document.getElementsByClassName('nodes')).forEach(function (element) {
            if(!element.classList.contains('hidden') && !element.classList.contains('default')){
                element.classList.add('hidden');
            }
        });
        Array.from(document.getElementsByClassName('lines')).forEach(function (element) {
            if(!element.classList.contains('hidden') && !element.classList.contains('default')){
                element.classList.add('hidden');
            }
        });
        org.style.transform = 'matrix(0.815376, 0, 0, 0.815376, -76, -5) scale(' + `${this.scale}` + ')';
      },
      setDefaults () {
        let node = document.getElementsByClassName("node");
        Array.from(node).forEach(function(node) {
            if(node.offsetHeight > 0) {
                node.classList.add('default');
            }
        });
        let nodes = document.getElementsByClassName('nodes')
        Array.from(nodes).forEach(function(node) {
            if(node.offsetHeight > 0) {
                node.classList.add('default');
            }
        });
        let lines = document.getElementsByClassName('lines')
        Array.from(lines).forEach(function(node) {
            if(node.offsetHeight > 0) {
                node.classList.add('default');
            }
        });
      },
      updateNodeSizes() {
        let nodes = document.getElementsByClassName("title");
        let container = document.getElementsByClassName("styling-container");
        let maxContainerHeight = 0
        let maxNodeHeight = 0;
        Array.from(container).forEach(function(node) {
            if(node.offsetHeight > maxContainerHeight) {
                maxContainerHeight = node.offsetHeight
            }
        });
        Array.from(container).forEach(function(node) {
                node.style.height = maxContainerHeight + "px"
        });
        Array.from(nodes).forEach(function(node) {
            if(node.offsetHeight > maxNodeHeight) {
                maxNodeHeight = node.offsetHeight
            }
        });
        Array.from(nodes).forEach(function(node) {
                node.style.height = maxNodeHeight + "px"
        });
      },
      expandAll() {
        this.scale = 0.9
        Array.from(document.getElementsByClassName('node')).forEach(function (element) {
            if(element.classList.contains('slide-up')){
                element.classList.remove('slide-up');
            }
        });
        Array.from(document.getElementsByClassName('nodes')).forEach(function (element) {
            if(element.classList.contains('hidden')){
                element.classList.remove('hidden');
            }
        });
        Array.from(document.getElementsByClassName('lines')).forEach(function (element) {
            if(element.classList.contains('hidden')){
                element.classList.remove('hidden');
            }
        });
      },
      increaseView() {
        this.scale = this.scale + 0.1
      },
      decreaseView() {
        if(this.scale > 0.2) {
            this.scale = this.scale - 0.1
        }
      },
      clearDefaults() {
        Array.from(document.getElementsByClassName('node')).forEach(function (element) {
            if(element.classList.contains('default')){
                element.classList.remove('default');
            }
        });
        Array.from(document.getElementsByClassName('nodes')).forEach(function (element) {
            if(element.classList.contains('default')){
                element.classList.remove('default');
            }
        });
        Array.from(document.getElementsByClassName('lines')).forEach(function (element) {
            if(element.classList.contains('default')){
                element.classList.remove('default');
            }
        });
      },
      resizeOrg() {
        let container = document.getElementById("chart-container");
        console.log(container);
        let height = window.innerHeight - 140
        container.style.height = height + 'px'
      }
  },
  beforeDestroy() {
    window.orgChartContext = undefined;
    window.webix.eventRemove(this.webix_resize_event);
  },
  mounted(){
    this.is_loading = true
    this.resizeOrg()
    this.clearDefaults();
    window.orgChartContext = this;
    if(this.levelEmployeeData == null){
        this.loadEmployeesData(this.selected_level.id)
        .then(()=>{
            this.$nextTick(()=>{
                let promises = [
                    this.executeOrgChart(),
                ]
                Promise.all(promises)
                .then(()=>{ this.$nextTick(()=>{ this.setDefaults(); this.expandAll(); this.updateNodeSizes(); this.resetChart(); }); })
                this.is_loading = false
            })
        })
    } else {
        this.$nextTick(()=>{
                let promises = [
                    this.executeOrgChart(),
                ]
                Promise.all(promises)
                .then(()=>{ this.$nextTick(()=>{ this.setDefaults(); this.expandAll(); this.updateNodeSizes(); this.resetChart();  }); })
                this.is_loading = false
        })
    } 
    let el = this
    this.webix_resize_event = window.webix.event(window, "resize", _.debounce(function(){
        el.resizeOrg()
    }, 100));
  },
  data() {
      return {
          name: "Select Organisation",
          show_chart: true,
          orgchart_instance: null,
          selectedOrgLevel: -1,
          mainLevel: -1,
          is_loading: false,
          scale: 1,
      };
  },
}
</script>
<style>
.orgchart {
    background-image: none;
    display: flex;
    transform: matrix(0.815376, 0, 0, 0.815376, -76, -5) scale(1);
    margin: 0px;
    padding: 0px;
}
#zoomLevel {
    margin: auto;
}
#fullName {
    font-weight: 900;
}
#jobTitle {
    font-weight: 550;
}
#chart-container {
    resize: vertical;
    margin: 0px;
    padding: 0px;
    min-height: 220px;
}
.orgchart .node {
    background-color: white;
    border: 3px solid #2a3042;
    border-radius: 5px;
    margin-left: 5px;
    margin-right: 5px;
    padding: 2px;
    min-width: 150px;
}

.orgchart .node .title {
    font-size: 0.7rem;
    background-color: #2a3042;
    min-width: 100%;
    height: auto;
    white-space: normal;
    padding: 1px;
}

.orgchart .node .content {
    border: 1px solid black;
}

.orgchart .table {
    margin-top: 0;
    margin-bottom: 0;
}

.lines {
    border-color: #2a3042;
}

.orgchart .lines .rightLine {
    border-color: #2a3042;
}

.orgchart .lines .leftLine {
    border-color: #2a3042;
}

.orgchart .lines .topLine {
    border-color: #2a3042;
}

.orgchart .lines .downLine {
    background-color: #2a3042;
}

.orgchart .node.focused {
    border-color: #34c38f;
    background-color: white;
}

.orgchart .node:hover {
    border-color: #34c38f;
    background-color: white;
}

.org-profile-img {
    margin: 0 auto;
    margin-top: 5px;
    margin-left: 8px;
    margin-right: 4px;
    max-width: 45px;
    max-height: 45px;
    
}

.rightEdge {
    margin-right: -10px;
}

.leftEdge {
    margin-left: -10px;
}

.topEdge {
    margin-top: -10px;
}

.bottomEdge {
    margin-bottom: -10px;
}

.main-content-container {
    margin: 2px;
    height: auto;
    align-items: center;
}

.title {
    min-height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fullName {
    margin-right: 5px;
    font-size: 0.7rem;
    text-align: center;
    vertical-align: middle;
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    word-break: break-word;
}

.jobTitle {
    flex-direction: column;
    align-items: center;
    word-break: break-word;
}

.heading p {
    margin-top: 5px;
    margin-bottom: 5px;
    padding-bottom: 0px;
}

.second-container {
    margin-top: 0.2rem;
}

.styling-container {
    display: flex;
    padding-left: 2px;
    padding-right: 2px;
    flex-direction: column !important;
    align-items: top !important;
    text-align: top !important;
    vertical-align: middle !important;
    align-items: top !important;
    justify-content: top !important;
}
   
</style>